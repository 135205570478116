import React from "react"
import PropTypes from "prop-types"
import {
  primaryButtonStyles,
  secondaryButtonStyles,
  tertiaryButtonStyles,
} from "./buttonStyles"
import analyticClickEvent from "./analytics"
import { Link } from "gatsby"
import cn from "classnames"

const ButtonLink = ({
  type = "primary",
  toPage,
  label,
  classNames = "",
  size,
  pageAnchorLink,
}) => {
  const url = typeof toPage === "string" ? toPage : `/${toPage?.cached_url}`

  const onClickHandler = () => {
    analyticClickEvent(label)
  }

  const className = cn(
    size === "large" && "px-12 py-4 font-bold",
    type === "primary" && primaryButtonStyles,
    type === "secondary" && secondaryButtonStyles,
    type === "tertiary" && tertiaryButtonStyles,
    classNames
  )

  return (
    <>
      {pageAnchorLink ? (
        <a href={pageAnchorLink} className={className} onClick={onClickHandler}>
          {label}
        </a>
      ) : (
        <Link to={url} className={className} onClick={onClickHandler}>
          {label}
        </Link>
      )}
    </>
  )
}

export default ButtonLink

ButtonLink.propTypes = {
  toPage: PropTypes.oneOfType([PropTypes.string, PropTypes.shape]),
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  classNames: PropTypes.string,
  size: PropTypes.string,
  anchor: PropTypes.string,
}
