const primaryButtonStyles =
  "block whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border-2 border-transparent shadow-sm text-base font-medium text-white bg-blue focus:bg-blue-lightier focus:border-blue-transparent hover:bg-blue-lightier hover:border-blue-lightier active:bg-blue-dark active:border-blue-dark disabled:opacity-20 disabled:cursor-default disabled:bg-blue focus:outline-none transition duration-500 ease-in-out"

const secondaryButtonStyles =
  "block whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-blue shadow-sm text-base text-blue bg-blue-lightest focus:border-blue-darker focus:bg-blue-lightest hover:bg-blue-midLight active:bg-blue-lighter active:border-blue-darker disabled:opacity-20 disabled:cursor-default disabled:bg-white transition duration-500 ease-in-out focus:outline-none"

const tertiaryButtonStyles =
  "block whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-blue shadow-sm text-base text-blue bg-white focus:border-gray-dark focus:bg-white hover:bg-blue-lightest active:bg-blue-lighter2 disabled:opacity-20 disabled:cursor-default disabled:bg-white transition duration-500 ease-in-out focus:outline-none"

const primaryPinkButtonStyles =
  "block whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border-2 border-transparent shadow-sm text-base font-medium text-white bg-pink focus:bg-pink-light focus:border-pink-transparent hover:bg-pink-light hover:border-pink-light active:bg-pink-dark active:border-pink-dark disabled:opacity-20 disabled:cursor-default disabled:bg-gray-light disabled:text-lighter focus:outline-none transition duration-500 ease-in-out"

const primaryPinkWhiteButtonStyles =
  "block whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border-2 border-transparent shadow-sm text-base font-medium text-pink bg-white focus:bg-pink-lightest focus:border-pink-transparent hover:bg-pink-lightest hover:border-pink-lightest active:bg-pink-midLight active:border-pink-midLight disabled:opacity-20 disabled:cursor-default disabled:text-lighter disabled:bg-gray-light focus:outline-none transition duration-500 ease-in-out"

export {
  primaryButtonStyles,
  secondaryButtonStyles,
  tertiaryButtonStyles,
  primaryPinkButtonStyles,
  primaryPinkWhiteButtonStyles,
}
