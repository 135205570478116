import React from "react"
import PropTypes from "prop-types"
import {
  primaryButtonStyles,
  secondaryButtonStyles,
  tertiaryButtonStyles,
  primaryPinkButtonStyles,
  primaryPinkWhiteButtonStyles,
} from "./buttonStyles"

const ButtonStyle = {
  blue: {
    default:primaryButtonStyles,
    inverse:primaryButtonStyles
  },
  pink: {
    default: primaryPinkButtonStyles,
    inverse: primaryPinkWhiteButtonStyles,
  },
}

const ButtonExternalLink = ({
  theme = "blue",
  type = "primary",
  href,
  label,
  className,
  size,
  newTab,
  inverse
}) => {
  return (
    <>
      {type === "primary" && (
        <a
          href={href}
          rel="noreferrer"
          className={`${
            inverse ? ButtonStyle[theme].inverse : ButtonStyle[theme].default
          }  ${size === "large" && `px-12 py-4`} ${className}`}
          target={newTab ? "_blank" : ""}
        >
          {label}
        </a>
      )}

      {type === "secondary" && (
        <a
          href={href}
          rel="noreferrer"
          className={`${secondaryButtonStyles} ${
            size === "large" && `px-12 py-4`
          }  ${className}`}
          target={newTab ? "_blank" : ""}
        >
          {label}
        </a>
      )}

      {type === "tertiary" && (
        <a
          href={href}
          rel="noreferrer"
          className={`${tertiaryButtonStyles} ${
            size === "large" && `px-12 py-4`
          }  ${className}`}
          target={newTab ? "_blank" : ""}
        >
          {label}
        </a>
      )}
    </>
  )
}

export default ButtonExternalLink

ButtonExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  label: PropTypes.string.isRequired,
  classNames: PropTypes.string,
  size: PropTypes.string,
}
